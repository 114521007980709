import styled from "@emotion/styled"

export const TLDR = styled.div`
  border: 1px solid gainsboro;
  background: #fbfbfb;
  display: inline-flex;
  padding: 20px;
  font-size: 1.2em;
  margin: 20px 0 40px;

  span {
    font-weight: bold;
    margin-right: 15px;
    border-right: 1px solid gainsboro;
    padding-right: 15px;
    display: flex;
    align-items: center;
  }
`
